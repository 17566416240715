import React, {useEffect, useState} from 'react';
import { withStyles, Typography, Button,
	Card, CardContent
} from '@material-ui/core';
import ReactPlayer from 'react-player'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from 'react-router-dom';
import email from '../../assets/images/ic_mail_outline_24px.svg';
import downloadIcon from '../../assets/images/download_icon.svg';
import personLogo from '../../assets/images/person-logo.svg';
import Footer from '../Footer';
import EventHeader from '../EventHeader';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

function EventDetails(props){
	const[event, setEventInfo] = useState({})
	const[eventNews, setEventNewsinfo] = useState({})
	//08e0
	useEffect(() => {
		loadEventInfo()
		loadEventNews()
	}, [])

	let loadEventInfo = () => {
		// fetch(`http://192.168.1.2:3001/en/api/v2/events/search_by_uri/${props.match.params.event_uri}?include_participants=true`)
		fetch(`https://connect.eventtia.com/en/api/v2/events/search_by_uri/${props.match.params.event_uri}?include_participants=true`)
		//fetch(`http://192.168.1.5:3001/en/api/v2/dbf4ecc7-f29a-4c28-9/events/list?page_size=9&page=${page}&keywords=${keyword}`)
		//&& event.participant_types != undefined && event.participant_types.length > 0
		.then(results => results.json())
		.then((data) => {
			setEventInfo(data);
			console.log(data)
		})
	}
	let loadEventNews = () => {
		fetch(`https://connect.eventtia.com/en/api/v2/news-files/${props.match.params.event_uri}?`)
		//fetch(`http://192.168.1.5:3001/en/api/v2/dbf4ecc7-f29a-4c28-9/events/list?page_size=9&page=${page}&keywords=${keyword}`)
		.then(results => results.json())
		.then((data) => {
			setEventNewsinfo(data.event_files)
		})
	}

	let parseDate = (dateString) => {
		const humanDate = new Date(dateString)
		// const humanDate = new Date(dateString.split(' ')[0])
		console.log(dateString)
		console.log(humanDate)
		const monthNames = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun',
			'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
			];
		return(
			<div  className={props.classes.dateNumber} >
				<p className={props.classes.number} >{dateString.split(' ')[0].split('-')[2]}</p>
				<p className={props.classes.text} >{monthNames[humanDate.getMonth()]}</p>
			</div>
		)
	}

	let registerUrl = () => {
		if(event.site_url.includes('https://live')) {
			return (
				<a href={`https://live.eventtia.com/${event.language}/${event.event_uri}`} target='_blank' rel="noopener noreferrer" className={props.classes.registerUrl
				}>
					<Button variant="contained" color="secondary" className={props.classes.button}>
						{(event.participant_types.length > 0) ? (
							'REGISTRO'
						) : (
							'Más información'
						)}
					</Button>
				</a>
			)
		}else{
			return (
				<Link to={`/${event.event_uri}/register`} className={props.classes.registerUrl
				}>
					<Button variant="contained" color="secondary" className={props.classes.button}>
						REGISTRO
					</Button>
				</Link>
			)
		}
	}
	
	const categoryColor = (categoryID) => {
		const colors = [{id: 16, color: 'rgb(250, 239, 56)'}, {id: 17, color: '#AE6143'}, {id: 18, color: 'rgb(83, 146, 41)'}, {id: 19, color: '#D7D4C2'}, {id: 20, color: 'rgb(231,157,37)'}, {id: 21, color: 'rgb(49, 28, 158)'}, {id: 38, color: 'rgb(211, 35, 95)'}]
		const categoryInfo =  colors.filter(function(info) {
			if(info.id === categoryID){
				return info.color
			}
		})
		if(categoryInfo.length > 0){
			return categoryInfo[0].color
		}
		return '#2F875F'
	}

	let buildCarouselItem = (item) => {
			if (item.event_file_content_type.includes('video')){
				return <div key={item.id} style={{position: 'relative'}}><ReactPlayer
					url={item.urls.original}
					width='100%'
					height='100%'
					controls
				/></div>
			}
			if (item.event_file_content_type.includes('image')){
				return <img src={item.urls.medium} style={{width: '100%', height: '21vh', position: 'relative', objectFit: 'cover'}} key= {item.id} />
			}
		
	}

  return(
		<div className={props.classes.eventDetails}>
			{Object.keys(event).length !== 0 ? (
				<div>
					<EventHeader
						event={event}
					/>
					<div className='new-details'>
						<div className='extended-details'>
							
							<Typography variant='h5' style={{color: '#3F4725', fontWeight: 600, fontSize: '24px', fontFamily: 'Acherus'}}>{event.name}</Typography>
							<p style={{color: '#707070', fontSize: '14px', fontFamily: 'Lato, sans-serif'}}>Fecha Inicio: {event.start_date.split(' ')[0]}</p>
							<p style={{color: '#707070', fontSize: '16px', fontFamily: 'Lato, sans-serif'}}>{event.description}</p>
							{ (new Date(event.end_date.replace(/ /g,"T")) >= new Date()) ? (
								registerUrl()
							) : (
								null
							)}
						</div>
						<div className='contact-info'>
							
							<div className='icon'><img src={personLogo} alt='logo'/></div>
							<div className='contact-details'>
								<Typography style={{color: '#707070', fontSize: '16px', fontFamily: 'Acherus'}}>Contacto</Typography>
								<p className='info'>{event.organizer_name}</p>
								<p className='info'>{event.sender_email}</p>
							</div>
						</div>
					</div>
				</div>
			): (
				null
			)}
			<Footer />
		</div>
	)
}

const styleSheet = theme => ({
	eventDetails: {
		'& .new-details': {
			margin: '5% 10% 5%',
			display: 'flex',
			justifyContent: 'space-around',
			[theme.breakpoints.down('sm')]: {
				flexWrap: 'wrap'
			},
			'& .extended-details': {
				width: '50%',
				textAlign: 'left',
				[theme.breakpoints.down('sm')]: {
					width: '100%'
				},
			}, 
			'& .contact-info': {
				boxShadow: '0px 3px 6px #00000029',
				width: '30%',
				display: 'flex',
				height: 'fit-content',
				paddingBottom: '20px',
				[theme.breakpoints.down('sm')]: {
					width: '100%'
				},
				'& .contact-details': {
					marginTop: '20px',
					textAlign: 'left',
					'& .info': {
						color: '#707070',
						fontSize: '12px'
					}
				},
				'& .icon': {
					margin: '20px'
				}
			}
		},
		'& .details': {
			margin: '5% 10% 0',
			display: 'flex',
			justifyContent: 'space-between',
			'& .card-info': {
				borderRadius: '20px 20px 0 0',
				'& .right-side': {
					width: '100%',
					'& .location-info': {
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						[theme.breakpoints.down('sm')]: {
							flexWrap: 'wrap'
						},
						'& .font-size-17': {
							fontWeight: '400', 
							fontFamily: 'Lato, sans-serif',
							fontSize: '17px'
						}
					}
				}
			},
			'& .contact': {
				margin: '3% 0', 
				color: 'white',
				display: 'flex', 
				justifyContent: 'space-evenly',
				padding: '10px',
				alignItems: 'center',
				fontFamily: 'Lato, sans-serif',
				fontSize: '16px',
				fontWeight: '400',
				[theme.breakpoints.down('sm')] : {
					flexWrap: 'wrap'
				}
			}
		}
	},
	button: {
		backgroundColor: '#7BC142 !important',
		color: 'white',
		height: '40px',
		width: '180px',
		borderRadius: '40px', 
		fontFamily: 'Acherus', 
		fontSize: '14px'
	},
	detailsBody: {
		display: 'flex',
		padding: '30px',
		[theme.breakpoints.down('sm')]: {
			flexWrap: 'wrap'
		}
	},

	dateNumber: {
		fontSize: '50px', 
		marginRight: '35px',
		color: 'gray',
		[theme.breakpoints.down('sm')]:{
			display: 'flex',
			alignItems:  'center'
		},
	},
	number: {
		fontWeight: '900', 
		fontFamily: 'Lato, sans-serif', 
		fontSize: '60px', 
		margin: 'unset'
	},
	text: {
		fontWeight: '400', 
		fontFamily: 'Lato, sans-serif', 
		fontSize: '48px', 
		margin: 'unset', 
		textTransform: 'uppercase'
	},
	carouselStyle: {
		height: '30vh',
		'& ul': {
			display: 'flex',
			alignItems: 'center',
			'& li': {
				margin: '0 10px 0 0'
			}
		}
	},
	filesContent: {
		display: 'flex',
		flexWrap: 'wrap'
	},

	sectionTitle: {
		textAlign: 'left',
		color: '#404040',
		fontSize: '20px', 
		fontWeight: 'bold', 
		fontFamily: 'Lato, sans-serif'
	},
	registerUrl: {
		textDecoration: 'none',
		fontFamily: 'Lato, sans-serif',
		fontSize: '17px',
		fontWeight: '700'
	}
})

export default withStyles(styleSheet)(EventDetails)