import React from 'react';
import { withStyles, Typography } from '@material-ui/core'; 

const EventHeader = (props) => { 
  const event = props.event;
  const categoryColor = (categoryID) => {
		const colors = [{id: 16, color: 'rgb(250, 239, 56)'}, {id: 17, color: '#AE6143'}, {id: 18, color: 'rgb(83, 146, 41)'}, {id: 19, color: '#D7D4C2'}, {id: 20, color: 'rgb(231,157,37)'}, {id: 21, color: 'rgb(49, 28, 158)'}, {id: 38, color: 'rgb(211, 35, 95)'}]
		const categoryInfo =  colors.filter(function(info) {
			if(info.id === categoryID){
				return info.color
			}
		})
		if(categoryInfo.length > 0){
			return categoryInfo[0].color
		}
		return '#57493E'
	}
  return(
    <div>
      <div className='hola-class' style={{position: 'relative'}}>
        <img src={event.hero.large_url} style={{width: '100%', height: 'auto', marginBottom: '-4px'}} alt='header-img'></img>
        <div className='last-div' style={{position: 'absolute', top: 0, right: 0, left: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', opacity:  '0.4'}}></div>
      </div>
      <div className={props.classes.headerFooter} style={{backgroundColor: `${categoryColor(event.category_id)}`}}></div>
    </div>
  )
}

//1. quitar border
//2. poner position relative
//3.poner div con position absolute y el color
const styleSheet = theme => ({
  header: {
    color: 'white',
    backgroundSize: 'cover', 
    backgroundPosition: 'center', 
    height: '40vh', 
    textAlign: 'left',
    display: 'table',
    width: '100%',
    '& .header-container': {
      display: 'table-cell',
      verticalAlign: 'middle',
      padding: '0 10%',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      top: 0, 
    }
  },
  title: {
		fontWeight: '700', 
		fontFamily: 'Lato, sans-serif', 
		fontStyle: 'normal', 
		fontSize: '30px',
		[theme.breakpoints.down('sm')]: {
			fontSize: '22px'
		}
  },
  categoryLabel: {
		color: 'white',
		borderRadius: '5px',
		height: '20px',
		padding: '0 10px',
		display: 'flex',
		alignItems: 'center',
		width: 'fit-content',
		'& p': {
			padding: 'unset',
			margin: 'unset',
			textTransform: 'uppercase', 
			fontSize: '15px'
		}
  },
  imageHover: {
    position:'absolute',
    top: '39%',
    left: '39%',
    transform: 'translate(-50%, -50%)', 
    color: 'white',
    [theme.breakpoints.down('sm')]: {
			top: '50%'
		},
  },
  headerFooter: {
    height: '100px',
    display: 'none',
    [theme.breakpoints.down('sm')]: {
			height: '50px'
		},
  }
})
export default withStyles(styleSheet)(EventHeader)