import React from 'react';
import ReactIframeResizer from 'react-iframe-resizer-super';
import { withStyles, Typography
} from '@material-ui/core';
import Footer from '../Footer';
import headerImg from '../../assets/images/qa-img.jpg';

const Faq = (props) => {
  return(
    <div className={'container'}>
      <div className={props.classes.header} style={{backgroundImage: `url(${headerImg})`}}>
        <div className='header-container'>
          <Typography variant='h6' className={props.classes.title}>Preguntas Frecuentes</Typography>
        </div>
      </div>
      <div className={props.classes.iframeContainer}>
        <ReactIframeResizer 
          src={`https://live.eventtia.com/en/faq`}
          className={props.classes.iframe}
        />
      </div>
      <Footer />
    </div>
  )
}

const styleSheet = theme => ({
  header: {
    color: 'white',
    backgroundSize: 'cover', 
    backgroundPosition: 'center', 
    height: '45vh', 
    textAlign: 'left',
    display: 'table',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: '30vh'
    },
    '& .header-container': {
      display: 'table-cell',
      verticalAlign: 'middle',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      top: 0, 
      padding: '10% 10%'
    }
  },
  title: {
    fontWeight: '700', 
		fontFamily: 'Lato, sans-serif', 
		fontStyle: 'normal', 
		fontSize: '30px',
		[theme.breakpoints.down('sm')]: {
			fontSize: '16px'
		}
  },
  iframe: {
    width: '100%'
  },
  iframeContainer: {
    paddingLeft: '6%',
    paddingRight: '6%',
    [theme.breakpoints.down('sm')]: {
			paddingLeft: '1%',
      paddingRight: '1%',
		}
  }
})
export default withStyles(styleSheet)(Faq);