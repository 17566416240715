import React from 'react';
import { withStyles} from '@material-ui/core';
import { Link } from 'react-router-dom';

const HomeHeader = (props) => {
  const event = props.events[0];
  if(!event)
    return null;
  const eventName = event.name.toLowerCase();
  const eventDescription = event.description.toLowerCase()
  return(
    <div className={props.classes.parentHeader}>
      <img src={event.hero.large_url} style={{width: '100%', height: 'auto', marginBottom: '-4px'}} alt={event.name} />
      <div className='details'>
        <div className='img'>
          <img src={event.logo.large_url} style={{width: '50%', height: 'auto', marginBottom: '-4px'}} alt={event.name} />
        </div>
        <div className='event-description'>
          <label className='title'>{ eventName.charAt(0).toUpperCase() + eventName.slice(1)}</label>
          <p className='description'>
            {eventDescription && eventDescription.charAt(0).toUpperCase() + eventDescription.slice(1)}
          </p>
          <p className='see-more'>
            <Link to={`${event.event_uri}/details`} className='link'>
              Conoce más...
            </Link>
          </p>
        </div>
      </div>
    </div>
  )
}

const styleSheet = theme => ({
  parentHeader: {
    '& .details': {
      display: 'flex',
      marginTop: '5%',
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
      },
      '& .img': {
        width: '50%',
        textAlign: 'right',
        [theme.breakpoints.down('sm')]: {
          width: '100%',
          textAlign: 'center',
        },
      },
      '& .event-description': {
        width: '50%',
        textAlign: 'left',
        paddingRight: '10%',
        paddingLeft: '20px',
        [theme.breakpoints.down('sm')]: {
          width: '100%',
          textAlign: 'center',
          padding: '5%'
        },
        '& .title': {
          color: '#3F4725',
          fontSize: '24px',
          fontWeight: 600, 
          fontFamily: 'Acherus', 
        },
        '& .description': {
          color: '#707070',
          overflow: 'hidden',
          display: '-webkit-box',
          '-webkit-line-clamp': 3,
          '-webkit-box-orient': 'vertical',
          fontFamily: 'Lato, sans-serif',
          fontSize: '16px', 
          textAlign: 'justify'
        },
        '& .see-more .link': {
          color: '#7BC142',
          fontWeight: '600',
          textDecoration: 'none', 
          fontFamily: 'Acherus'
        }
      }
    } 
  }
})
export default withStyles(styleSheet)(HomeHeader);