import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  AppBar, Toolbar, Typography, List, ListItem,
  withStyles, Grid, SwipeableDrawer
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-router-dom';

//assets
//import logo from '../assets/images/navbar-logo.png'
import logo from '../assets/images/logo-ueb.svg'
const styleSheet = {
  list : {
    width : 200,
  },
  padding : {
    paddingRight : 30,
    cursor : "pointer",
    fontSize: '14px',
    fontFamily: 'Acherus',
    fontWeight: '600',
    color: '#3F4725'
  },

  sideBarIcon : {
    padding : 0,
    color : "black",
    cursor : "pointer",
  }
}

function Navbar(props){
  const [drawerActive, setDrawerActive] = useState(false);
  const [drawer, setDrawer] = useState(false);
  useEffect(() => {
    
    if(window.innerWidth <= 600){
      setDrawerActive(true)
    }

    window.addEventListener('resize',()=>{
      if(window.innerWidth <= 600){
        setDrawerActive(true)
      }
      else{
        setDrawerActive(false)
      }
    });
  }, []);

  //Small Screens
  let createDrawer = () => {
    return (
      <div>
        <AppBar style={{backgroundColor: '#F2F2F2', color:'black'}} position= 'static'>
          <Toolbar>
            <Grid container direction = "row" justify = "space-between" alignItems="center">
              <MenuIcon
                className = {props.classes.sideBarIcon}
                onClick={()=>{setDrawer(true)}} />

              <Typography color="inherit" variant = "headline"><Link to='/'><img style={{height: '40px'}} src={logo} alt='lo'></img></Link></Typography>
              <Typography color="inherit" variant = "headline"></Typography>
            </Grid>
          </Toolbar>
        </AppBar>

        <SwipeableDrawer
         open={drawer}
         onClose={()=>{setDrawer(false)}}
         onOpen={()=>{setDrawer(true)}}
         style={{backgroundColor: '#F2F2F2', color:'#3F4725'}}>

           <div
             tabIndex={0}
             role="button"
             onClick={()=>{setDrawer(false)}}
             onKeyDown={()=>{setDrawer(false)}}>

            <List className = {props.classes.list}>
              <ListItem key = {1} button divider><a href = 'https://www.unbosque.edu.co' target='_blank' rel="noopener noreferrer"  style={{textDecoration: 'none', color: '#3F4725', width: '100%'}}>NUESTRO BOSQUE</a></ListItem>
              <ListItem key = {2} button divider><a href = 'https://www.unbosque.edu.co/centro-informacion/eventos' target='_blank' rel="noopener noreferrer"  style={{textDecoration: 'none', color: '#3F4725', width: '100%'}}>PRINCIPAL</a></ListItem>
              <ListItem key = {3} button divider><Link to='/about-us' style={{textDecoration: 'none', color: 'black', width: '100%', display: 'none'}}>¿Quiénes somos?</Link></ListItem>
              <ListItem key = {4} button divider><a href='https://connect.eventtia.com/users/sign_in' target='_blank' rel="noopener noreferrer" style={{textDecoration: 'none', color: 'white', fontFamily: 'Acherus'}}>Administración </a></ListItem>
             </List>

         </div>
       </SwipeableDrawer>

      </div>
    );
  }

  //Larger Screens
  let destroyDrawer = () => {
    const {classes} = props
    return (
      <AppBar style={{backgroundColor: '#F2F2F2', color: 'black', padding: '0 10%', boxShadow: 'none'}} position='sticky'>
        <Toolbar style={{padding: 'unset'}}>
          <Typography variant = "h6" style={{flexGrow:1, textAlign: 'left'}} color="inherit" ><Link to='/'><img style={{height: '40px', }} src={logo} alt='lo'></img></Link></Typography>
          <a href = 'https://www.unbosque.edu.co' target='_blank' rel="noopener noreferrer"   style={{textDecoration: 'none', color: '#3F4725'}}><Typography variant = "subtitle1" className = {classes.padding} color="inherit" >NUESTRO BOSQUE</Typography></a>
          <a href = 'https://www.unbosque.edu.co/centro-informacion/eventos' target='_blank' rel="noopener noreferrer"   style={{textDecoration: 'none', color: '#3F4725'}}><Typography variant = "subtitle1" className = {classes.padding} color="inherit" >PRINCIPAL</Typography></a>
          <Link to='/faq' style={{textDecoration: 'none', color: '#3F4725', display: 'none'}}><Typography variant = "subtitle1" className = {classes.padding} color="inherit" >PREGUNTAS FRECUENTES</Typography></Link>
          <Link to='/about-us' style={{textDecoration: 'none', color: 'black', display: 'none'}}><Typography variant = "subtitle1" className = {classes.padding} color="inherit" >¿Quiénes somos?</Typography></Link>
          <Typography variant = "subtitle1" className = {classes.padding} color="inherit" ><a href='https://connect.eventtia.com/users/sign_in' target='_blank' rel="noopener noreferrer" style={{textDecoration: 'none', color: 'white', backgroundColor: '#3E4524', borderRadius: '40px', padding: '10px 25px', fontFamily: 'Acherus'}}>Administración </a></Typography>
        </Toolbar>
      </AppBar>
    );
  }
  return(
    <div>
      {drawerActive ? createDrawer() : destroyDrawer()}
    </div>
  );
}

Navbar.propTypes = {
  classes : PropTypes.object.isRequired
};



export default withStyles(styleSheet)(Navbar);