import React from 'react';
import { withStyles } from '@material-ui/core'; 
import { Link } from 'react-router-dom';
import facebook from '../assets/images/facebook.svg';
import instragram from '../assets/images/instagram.svg';
import twitter from '../assets/images/twitter.svg';
import youtube from '../assets/images/youtube.svg';
import chiaLogo from '../assets/images/chia-logo.png';
import bogoLogo from '../assets/images/bogo-logo.png';
import terms from '../assets/files/terms-conditions.pdf';
import eventtiaLogo from '../assets/images/eventtia-logo.png'
import excelencePath from '../assets/images/excelence-path.png'
function Footer(props){
  return(
		<div>
			<div className={props.classes.newFooterContainer}>
				<div>
					<div>
						<p className={props.classes.boldTitle}>Universidad Del Bosque</p>
					</div>
					<div className={props.classes.branchDetails}>
						<div className='branch-info'>
							<div className='logo'>
								<img src={bogoLogo} alt='excelence-path'/>
							</div>
							<div>
								<b>Instalaciones Bogotá</b>
								<p>
									Av. Cra. 9 No. 131 A - 02<br/>
									Línea gratuita:<br/>
									018000 113033<br/>
									PBX: (571) 648 9000<br/>
								</p>
							</div>
						</div>
						<div className='branch-info'>
							<div className='logo'>
								<img src={chiaLogo} alt='excelence-path'/>
							</div>
							<div>
								<b>Instalaciones Chía</b>
								<p>
									Autopista Norte<br/>
									Km. 20 costado occidental<br/>
									Vía Chía - Bogotá<br/>
									Teléfono: 676 3110<br/>
									Línea gratuita: 018000 113033<br/>
									PBX: (571) 648 9000<br/>
								</p>
							</div>
						</div>
						<div className='branch-info'>
							<div>
								<b>Admisiones</b>
								<p>
									PBX.: 648 9000<br />
									Línea gratuita:<br />
									018000 113033<br />
									Edificio Fundadores<br />
									Av. Cra. 9 No. 131 A - 02<br />
									Skype: /uelbosque<br />
									Correo electrónico:<br />
									<p style={{color: 'white'}}>atencionalusuario@unbosque.edu.co</p><br />
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="left-container">
					<div className='terms'>
						<p style={{marginRight: '10px'}}>Términos y condiciones</p>
						<p>Politica de privacidad</p>
					</div>
					<div className='social-media'>
						<p><a href='https://www.facebook.com/universidadelbosque' target='_blank' rel="noopener noreferrer"><img src={facebook} alt='face'></img></a></p>
						<p><a href='https://www.instagram.com/uelbosque/' target='_blank' rel="noopener noreferrer"><img src={instragram} alt='ins'></img></a></p>
						<p><a href='https://twitter.com/UElBosque' target='_blank' rel="noopener noreferrer"><img src={twitter} alt='twi'></img></a></p>
						<p><a href='https://www.youtube.com/user/universidadelbosque' target='_blank' rel="noopener noreferrer"><img src={youtube} alt='youtu'></img></a></p>
					</div>
					<p style={{textAlign: 'left', color: '#3E4724', fontSize: '12px'}}>
						Vigilada Mineducación.<br/> 
						Personería Jurídica otorgada mediante resolución 11153 del 4 de agosto de 1978.
					</p>
				</div>
				<div className='right-container'style={{display: 'none'}}>
					<img src={excelencePath} alt='excelence-path'/>
				</div>
			</div>
		</div>
	)
}

const styleSheet = theme => ({
	newFooterContainer: {
		backgroundColor: '#7BC242',
		display: 'flex',
		justifyContent: 'space-between',
		paddingLeft: '10%',
		paddingRight: '10%',
		paddingTop: '20px',
		[theme.breakpoints.down('sm')]: {
			flexWrap: 'wrap',
			'& .right-container': {
				'& img': {
					width: '90%'
				}
			}
		},
		'& .left-container': {
			'& .terms': {
				display: 'flex',
				'& p': {
					color: '#000000',
					fontSize: '16px',
					fontWeight: '600',
					textAlign: 'left'
				}
			},
			'& .social-media': {
				display: 'flex',
				'& p': {
					marginRight: '10px'
				}
			}
		},
	},
	boldTitle: {
		color: '#000000',
		fontSize: '16px',
		fontWeight: '600',
		textAlign: 'left'
	}, 
	branchDetails: {
		display: 'flex',
		[theme.breakpoints.down('sm')]: {
			flexWrap: 'wrap',
		},
		'& .branch-info': {
			textAlign: 'left',
			display: 'flex',
			fontSize: '12px', 
			padding: '0 15px',
			[theme.breakpoints.down('sm')]: {
				padding: '0',
			},
			'& .logo': {
				padding: '10px 10px 0 0'
			},
			'& b': {
				color: '#000000'
			},
			'& p' :{
				color: '#3E4724'
			}
		}
	}
});

export default withStyles(styleSheet)(Footer);