import React from 'react';
import { withStyles, Typography,
	Card, CardContent
} from '@material-ui/core';
import Footer from '../Footer';

import aboutHeaderImg from '../../assets/images/aboutHeader.png';
import adrianaAvatar from '../../assets/images/adriana-sierra.png';
import luisaAvatar from '../../assets/images/Luisa@2x.png';
import vaninaAvatar from '../../assets/images/Vanina@2x.png';
import alejandraAvatar from '../../assets/images/Alejandra@2x.png';
import nataliaAvatar from '../../assets/images/Natalia@2x.png';
import sandraAvatar from '../../assets/images/sandra-vaquiro.png';
import jennyAvatar from '../../assets/images/Jenny@2x.png';
import isabelAvatar from '../../assets/images/Isabel@2x.png';
import alejandraCancinoAvatar from '../../assets/images/alejandra-cancino.png';
import sirleyAvatar from '../../assets/images/Sirley@2x.png';
import email from '../../assets/images/ic_mail_outline_24px.svg';

const AboutUs = (props) => {
  return(
    <div className='container'>
      <div className={props.classes.header} style={{backgroundImage: `url(${aboutHeaderImg})`}}>
        <div className='header-container'>
          <Typography variant='h6' className={props.classes.title}>¿Quiénes somos?</Typography><br/>
          + 10 años de experiencia<br/>
          • Expertos en Asesoría y Producción de Eventos<br/><br/>
          <div className={props.classes.aboutUsInfo}>
            <div>
              <p className='section-title'>Misión</p>
              <p className='section-body'>Relaciones Públicas es una unidad que contribuye en la creación y el fortalecimiento de<br/> relaciones que construyen los miembros de la Comunidad Uniandina, a través de la asesoría y <br/> producción de eventos.</p>
            </div>
            <div>
              <p className='section-title'>Visión</p>
              <p className='section-body-vision'>
                Ser referente y líder en la planeación y gestión de eventos en la Universidad, fortaleciendo el<br/> 
                apoyo y asesoría a las facultades y unidades, creando lineamientos para la producción de<br/>
                eventos y contribuyendo al fortalecimiento de la marca Unbosque.
              </p>
            </div>
            <div>
              <p className='section-title'>Servicios</p>
              <p className='section-body-services'>
                • Apoyo integral en la logística y producción de eventos de impacto para la Universidad.<br/>
                • Asesorías de mejores prácticas, protocolos y lineamientos para el desarrollo de eventos.<br/>
                • Préstamo de elementos para eventos dentro de la Universidad. (según disponibilidad)<br/>
                • Administración del Portal de eventos.<br/>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Card className={props.classes.cardInfo}>
        <CardContent style={{padding: 'unset'}}>
          <div className={props.classes.containerInfo}>
            <div className='row-1'>
              <div className='avatar-container'>
                <img src={adrianaAvatar} alt='Adriana'/>
                <p>
                  <b>Adriana Sierra</b><br/>
                  Jefe<br/>
                </p>
              </div>
              <div className='avatar-container'>
                <img src={luisaAvatar} alt='luisa'/>
                <p>
                  <b>Luisa Bastidas</b><br/>
                  Coordinador<br/>
                </p>
              </div>
              <div className='avatar-container'>
                <img src={vaninaAvatar} alt='Vanina'/>
                <p>
                  <b>Vanina Jacob</b><br/>
                  Relacionista Pública<br/>
                </p>
              </div>
              <div className='avatar-container'>
                <img src={alejandraAvatar} alt='Alejandra'/>
                <p>
                  <b>Alejandra Isaza</b><br/>
                  Relacionista Pública<br/>
                </p>
              </div>
              <div className='avatar-container'>
                <img src={nataliaAvatar} alt='Natalia'/>
                <p>
                  <b>Natalia Reyes</b><br/>
                  Relacionista Pública<br/>
                </p>
              </div>
            </div>
            <div className='row-2'>
              <div className='avatar-container'>
                <img src={sandraAvatar} alt='sandra'/>
                <p>
                  <b>Sandra Vaquiro</b><br/>
                  Gestora Administrativa y Financiera<br/>
                </p>
              </div>
              <div className='avatar-container'>
                <img src={jennyAvatar} alt='jenny'/>
                <p>
                  <b>Jenny Cristancho</b><br/>
                  Asistente Logístico<br/>
                </p>
              </div>
              <div className='avatar-container'>
                <img src={isabelAvatar} alt='isabel'/>
                <p>
                  <b>Isabel Montes</b><br/>
                  Asistente Administrativo y Logístico<br/>
                </p>
              </div>
              <div className='avatar-container'>
                <img src={sirleyAvatar} alt='sirley'/>
                <p>
                  <b>Sirley Tabio</b><br/>
                  Asistente Administrativo y Logístico<br/>
                </p>
              </div>
              <div className='avatar-container'>
                <img src={alejandraCancinoAvatar} alt='alejandra-cancino'/>
                <p>
                  <b>Alejandra Cancino</b><br/>
                  Relacionista Pública<br/>
                </p>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
      <div className={props.classes.contact} >
        <p style={{fontWeight: 'bold'}}>Teléfono: 3394949 ext. 2006 - 5026 - 5416</p>
        <p><a style={{textDecoration: 'none', color: 'white'}} href={'mailto:relacionespublicas@unbosque.edu.co'}><img src={email} alt='em' style={{marginRight: '10px'}}/>relacionespublicas@unbosque.edu.co</a></p>
      </div>
      <Footer />
    </div>
  )
}

//1. quitar border
//2. poner position relative
//3.poner div con position absolute y el color
const styleSheet = theme => ({
  header: {
    color: 'white',
    backgroundSize: 'cover', 
    backgroundPosition: 'center', 
    height: '45vh', 
    textAlign: 'left',
    display: 'table',
    width: '100%',
    '& .header-container': {
      display: 'table-cell',
      verticalAlign: 'middle',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      top: 0, 
      padding: '10% 10%'
    }
  },
  title: {
		fontWeight: '700', 
		fontFamily: 'Lato, sans-serif', 
		fontStyle: 'normal', 
		fontSize: '30px',
		[theme.breakpoints.down('sm')]: {
			fontSize: '16px'
		}
  },
  aboutUsInfo: {
    '& div': {
      justifyContent: 'flex-start',
      fontFamily: 'Lato, sans-serif',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap'
      }
    },
    '& div .section-title': {
      color: '#FFF000',
      fontSize: '20px', 
      fontFamily: 'Lato, sans-serif',
      fontWeight: 700,
      [theme.breakpoints.down('sm')]: {
        margin: '5px 0',
        fontSize: '16px'
      }
    },
    '& div .section-body': {
      fontSize: '15px', 
      fontFamily: 'Lato, sans-serif',
      fontWeight: 400,
      paddingLeft: '110px',
      width: '100%',
      lineHeight: '25px',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        margin: 'unset'
      }
    },
    '& div .section-body-services': {
      fontSize: '15px', 
      fontFamily: 'Lato, sans-serif',
      fontWeight: 400,
      paddingLeft: '90px',
      width: '100%',
      lineHeight: '25px',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        margin: 'unset'
      }
    },
    '& div .section-body-vision': {
      fontSize: '15px', 
      fontFamily: 'Lato, sans-serif',
      fontWeight: 400,
      paddingLeft: '115px',
      width: '100%',
      lineHeight: '25px',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        margin: 'unset'
      }
    }
  },
  cardInfo: {
    margin: '-3% 10% 10%',
    borderRadius: '20px 20px 0 0',
    boxShadow: '0px 1px 20px 0px rgba(0,0,0,0.07), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.06)'
  },
  containerInfo: {
    '& div': {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center'
    },
    '& .row-1': {width: '90%', margin: '0 auto'},
    '& .row-1 .avatar-container': {
      marginTop: '3%',
      padding: '0.5%',
      flexWrap: 'wrap',
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '19%',
      '& img': {
        height: '145px',
        width: '145px',
        margin: '0 auto'
      },
      '& p': {
        fontSize: '13px',
        fontFamily: 'Lato, sans-serif',
        fontWeight: 400,
        lineHeight: '20px',
        height: '100px',
        '& b': {
          fontSize: '15px'
        }
      }
    },
    '& .row-2 .avatar-container': {
      marginTop: '3%',
      padding: '0.5%',
      flexWrap: 'wrap',
      display: 'flex',
      flexDirection: 'column',
      '& img': {
        height: '145px',
        width: '145px',
        margin: '0 auto'
      },
      '& p': {
        fontSize: '13px',
        fontFamily: 'Lato, sans-serif',
        fontWeight: 400,
        lineHeight: '20px',
        height: '100px',
        '& b': {
          fontSize: '15px'
        }
      }
    }
  },
  contact: {
    backgroundColor: '#1695A3', 
    margin: '2% 10%', 
    color: 'white',
    display: 'flex', 
    justifyContent: 'space-evenly',
    padding: '10px',
    alignItems: 'center',
    fontFamily: 'Lato, sans-serif',
    fontSize: '16px',
    fontWeight: '400',
    [theme.breakpoints.down('sm')] : {
      flexWrap: 'wrap'
    }
  }
})
export default withStyles(styleSheet)(AboutUs)