import React from 'react';
import { withStyles
} from '@material-ui/core';
import pcGroup from '../../assets/images/pcGroup.svg';
const NotFound = (props) => {
  return (
      <div className={props.classes.container}>
        <div>
          <img src={pcGroup} alt='pc' />
        </div>
        <div className={props.classes.textBody}>
          <h3 className='title'>No encontramos la página que estás buscando</h3>
          <div className='description'>
            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et</p>
          </div>
        </div>
      </div>      
  )
}

const styleSheet = theme => ({
  container: {
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center',
    height: '90vh', 
    margin: '0 10%',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap'
    }
  },
  textBody: {
    textAlign: 'left',
    paddingLeft: '5%',
    fontFamily: 'Lato, sans-serif', 
    '& .title': {
      fontWeight: 'bold', 
      fontSize: '20px'
    },
    '& .description': {
      fontSize: '15px',
      fontWeight: 400,
      color: '#707070'
    }
  }
});
export default withStyles(styleSheet)(NotFound)